* {
  font-family: Helvetica !important;
}

button {
  font-family: Helvetica !important;
}

.button-black.button-clear,
.button-black.button-outline {
  background-color: transparent;
  color: #606c76;
  border-color: #606c76;
}

.button-black.button {
  background-color: #606c76;
  border-color: #606c76;
  color: white;
}

.pill {
  border-radius: 999px;
}

.alert {
  display: flex;
  align-items: center;
  width: 80vw;
  color: white;
  background-color: #f08080;
  padding: 15px;
  border-radius: 10px;
  justify-content: space-between;
}

.alert a {
  color: white;
  float: right;
}

input {
  border-radius: 999px !important;
  color: #606c76;
  font-weight: 200;
}

input:focus {
  border-color: #606c76 !important;
}

textarea {
  border-radius: 20px;
  padding: 10px !important;
  color: #606c76;
  font-weight: 200;
}

textarea:focus {
  border-color: #606c76 !important;
}
