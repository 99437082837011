.navbar {
  display: flex !important;
  flex-direction: row !important;
  justify-content: space-between;
  padding-top: 15px;
  padding-bottom: 15px;
  align-items: center;
}

@media only screen and (max-width: 600px) {
  .logout-button {
    width: 30vw !important;
  }
}
