.otp-container {
  display: flex;
  height: 100vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 40vw;
}

@media only screen and (max-width: 600px) {
  .otp-container {
    display: flex;
    height: 100vh;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 80vw;
  }

  .button {
    width: 100%;
  }
}
