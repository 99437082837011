.body {
  resize: none;
  height: 300px;
}

.add-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media only screen and (max-width: 600px) {
  .add-container {
    width: 90vw;
    flex-direction: column;
  }

  button {
    width: 100%;
  }
}
