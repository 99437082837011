.fade-in-button {
  -o-transition: opacity 0.15s ease-in-out, visibility 0.15s ease-in-out !important;
  -ms-transition: opacity 0.15s ease-in-out, visibility 0.15s ease-in-out !important;
  -moz-transition: opacity 0.15s ease-in-out, visibility 0.15s ease-in-out !important;
  -webkit-transition: opacity 0.15s ease-in-out, visibility 0.15s ease-in-out !important;
  transition: opacity 0.15s ease-in-out, visibility 0.15s ease-in-out !important;
  /* ...and now override with proper CSS property */
  visibility: hidden;
  opacity: 0;
}

.post-hover:hover .fade-in-button {
  visibility: visible;
  opacity: 1;
}

.post {
  margin-top: 20px;
  margin-bottom: 50px;
}
